import Alpine from "alpinejs";
import axios from "axios";
import Bs5Utils from "bs5-utils";
import { v4 as uuidv4 } from "uuid";

Bs5Utils.defaults.toasts.position = "top-center";
Bs5Utils.defaults.toasts.container = "toast-container";
Bs5Utils.defaults.toasts.stacking = true;

const bs5Utils = new Bs5Utils();

const today = new Date();

window.Alpine = Alpine;

const trainingViewer = document.getElementById("training-viewer");
const ftTrainingCard = document.getElementById("ft-training");
const vetTrainingCard = document.getElementById("vet-training");

ftTrainingCard.addEventListener("show.bs.collapse", (event) => {
  trainingViewer.scrollIntoView({ behavior: "smooth" });
});

vetTrainingCard.addEventListener("show.bs.collapse", (event) => {
  trainingViewer.scrollIntoView({ behavior: "smooth" });
});

function formSubmitter(trainingType) {
  return {
    isLoading: false,
    first_name: "",
    last_name: "",
    email: "",
    alt_email: "",
    training_type: trainingType,
    submission_date: today.toISOString(),
    id: uuidv4(),
    async submit() {
      this.isLoading = true;
      try {
        await axios.post("https://sheetdb.io/api/v1/fz23i730v1bfo", {
          ID: this.id,
          first_name: this.first_name.trim(),
          last_name: this.last_name.trim(),
          email: this.email.trim().toLowerCase(),
          alt_email: this.alt_email.trim().toLowerCase(),
          submission_date: this.submission_date,
          training_type: this.training_type,
        });
        bs5Utils.Modal.show({
          type: "success",
          title: "Training recorded",
          content: `<h3>🎉 Congratulations, ${this.first_name}!</h3><p>Your reviewer training record has been updated. You will soon receive your assignments via Oxford Abstracts. Thank you for your service to the conference.</p><p>ID: <span class="small text-muted">${this.id}</span>
          `,
          centered: true,
          dismissible: true,
          backdrop: true,
          keyboard: true,
          focus: true,
          fullscreen: false,
          size: "",
        });
        (this.first_name = ""),
          (this.last_name = ""),
          (this.email = ""),
          (this.alt_email = ""),
          (this.id = uuidv4());
      } catch (err) {
        bs5Utils.Toast.show({
          type: "danger",
          title: "Error",
          delay: 5000,
          content: `We could not submit your certification: ${JSON.stringify(
            err
          )}`,
          dismissible: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  };
}

Alpine.data("formSubmitter", formSubmitter);

Alpine.start();
